import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate, useLocation, Navigate } from 'react-router-dom';
import './App.css';
import gggLogo from './assets/GGGraceLogo.png';
import axios from 'axios';
import { UserProvider } from './UserContext';

// コンポーネントのインポート
import CastApplicationForm from './components/CastApplicationForm';
import CastList from './components/CastList';
import InterviewBooking from './components/InterviewBooking';
import Booking from './components/Booking';
import Register from './components/Register';
import VerifyEmail from './components/VerifyEmail';
import UserProfile from './components/UserProfile';
import AdminPage from './components/AdminPage';
import CastApplicationList from './components/CastApplicationList';
import ScheduleRegistration from './components/ScheduleRegistration';
import CastProfileEdit from './components/CastProfileEdit';
import CastDetail from './components/CastDetail';
import SwipeComponent from './components/SwipeComponent'; // スワイプ機能を持つコンポーネントをインポート
import PointsPurchase from './components/PointsPurchase'; // PointsPurchaseコンポーネントをインポート

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // ローディング状態を追加
  const navigate = useNavigate();
  const location = useLocation();
  const [bgColor, setBgColor] = useState('#FFFFFF'); // 背景を白に設定
  const [isSubMenuVisible, setSubMenuVisible] = useState(false); // サブメニューの表示状態

  useEffect(() => {
    const checkUserAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(
            'https://us-central1-gggrace-gamer.cloudfunctions.net/api/user',
            {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }
          );
          setUser(response.data);
        } catch (error) {
          console.error('ユーザー情報の取得に失敗しました:', error);
          localStorage.removeItem('token');
          setUser(null);
        }
      }
    };

    checkUserAuth();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // 5秒後にローディングを終了
    }, 5000); // 5秒

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    alert('ログアウトしました。');
    navigate('/');
  };

  const handleHomeClick = () => {
    setSubMenuVisible(false); // サブメニューを非表示にする
    handleNavigation('/'); // ホームに遷移
  };

  // 共通のレイアウト要素を関数として定義
  const commonLayout = (
    <>
      <div className="logo-container">
        <img src={gggLogo} alt="GGGrace Logo" className="logo" />
        <span className="logo-text">GGG</span>
      </div>
      
      <div className="nav-buttons">
        <a 
          href="#" 
          className="nav-button"
          onMouseEnter={() => setSubMenuVisible(true)} // ホバー時にサブメニューを表示
          onMouseLeave={() => setSubMenuVisible(false)} // ホバーを外したときに非表示
          onClick={handleHomeClick} // ホームボタンのクリックイベント
        >
          ホーム
        </a>
        {isSubMenuVisible && ( // サブメニューの表示
          <ul className="submenu" 
              onMouseEnter={() => setSubMenuVisible(true)} // サブメニューにカーソルが入ったとき
              onMouseLeave={() => setSubMenuVisible(false)} // サブメニューからカーソルが出たとき
          >
            <li>
              <a href="#" onClick={() => { handleNavigation('/points-purchase'); setSubMenuVisible(false); }}>ポイント購入</a> {/* サブメニュー項目 */}
            </li>
          </ul>
        )}
        <button 
          onMouseEnter={() => setSubMenuVisible(false)} // キャスト一覧にカーソルを合わせたときにサブメニューを非表示
          onClick={() => handleNavigation('/cast-list')} 
          className="nav-button"
        >
          キャスト一覧
        </button>
        {user && user.role === 'user' && (
          <button onClick={() => handleNavigation('/cast-application')} className="nav-button">キャスト申請</button>
        )}
        {user ? (
          <>
            <span className="user-name">ようこそ、{user.username || 'ゲスト'}さん</span>
            {(user.role === 'Admin' || user.role === 'Manager') && (
              <button onClick={() => handleNavigation('/admin')} className="nav-button">管理ページ</button>
            )}
            {user && (user.role === 'Admin' || user.role === 'Manager' || user.role === 'Cast') && (
              <button onClick={() => handleNavigation('/schedule-registration')} className="nav-button">スケジュール登録</button>
            )}
            {user && user.role === 'IntervieweeCast' && (
              <Link to="/interview-booking">面談予約</Link>
            )}
            {user && user.role === 'Cast' && (
              <button onClick={() => handleNavigation('/cast-profile-edit')} className="nav-button">プロフィール編集</button>
            )}
            <button onClick={handleLogout} className="nav-button">ログアウト</button>
          </>
        ) : (
          <button onClick={() => handleNavigation('/register')} className="nav-button create-account">アカウント作成</button>
        )}
      </div>
    </>
  );

  // ユーザーのロールに基づいてアクセスを制限するコンポーネント
  const ProtectedRoute = ({ element, allowedRoles }) => {
    if (!user) {
      return <Navigate to="/login" />;
    }
    if (allowedRoles.includes(user.role)) {
      return element;
    }
    return <Navigate to="/" />;
  };

  const loadingText = "オンラインゲームに新しい選択肢を";

  // ランダムに文字をシャッフルする関数
  const shuffleText = (text) => {
    const characters = text.split("");
    for (let i = characters.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [characters[i], characters[j]] = [characters[j], characters[i]];
    }
    return characters.join("");
  };

  const shuffledText = shuffleText(loadingText);

  return (
    <UserProvider>
      <div className={`App`} style={{ background: bgColor }}>
        {loading ? (
          <div className="loading-container">
            <img src={gggLogo} alt="GGGrace Logo" className="loading-logo" />
            <div className="loading-text">
              {loadingText.split("").map((char, index) => {
                // ランダムな遅延を生成（0.1sから1.5sの間）
                const randomDelay = Math.random() * 1.4 + 0.1; 
                return (
                  <span key={index} className="loading-char" style={{ animationDelay: `${randomDelay}s` }}>
                    {char}
                  </span>
                );
              })}
            </div>
          </div>
        ) : (
          <>
            {commonLayout}
            <main>
              <Routes>
                <Route path="/" element={<SwipeComponent setBgColor={setBgColor} setSubMenuVisible={setSubMenuVisible} />} />
                <Route path="/cast-application" element={<ProtectedRoute element={<CastApplicationForm />} allowedRoles={['user']} />} />
                <Route path="/cast-list" element={<CastList />} />
                <Route path="/interview-booking" element={<ProtectedRoute element={<InterviewBooking />} allowedRoles={['IntervieweeCast']} />} />
                <Route path="/booking" element={<Booking />} />
                <Route path="/register" element={<Register />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/admin" element={<ProtectedRoute element={<AdminPage />} allowedRoles={['Admin', 'Manager']} />} />
                <Route path="/cast-applications" element={<ProtectedRoute element={<CastApplicationList />} allowedRoles={['Admin', 'Manager']} />} />
                <Route path="/schedule-registration" element={<ProtectedRoute element={<ScheduleRegistration />} allowedRoles={['Admin', 'Manager', 'Cast']} />} />
                <Route path="/cast-profile-edit" element={<ProtectedRoute element={<CastProfileEdit />} allowedRoles={['Cast']} />} />
                <Route path="/casts" element={<CastList />} />
                <Route path="/cast/:id" element={<CastDetail />} />
                <Route path="/points-purchase" element={<PointsPurchase />} /> {/* 追加 */}
              </Routes>
            </main>
          </>
        )}
      </div>
    </UserProvider>
  );
}

export default App;
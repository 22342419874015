import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
  register: () => {},
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserData(token);
    }
  }, []);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/user',
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      localStorage.removeItem('token');
      setUser(null);
    }
  };

  const login = async (email, password) => {
    try {
      const response = await axios.post(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/login',
        { email, password },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const { idToken } = response.data;
      localStorage.setItem('token', idToken);
      await fetchUserData(idToken);
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const register = async (email, password, username) => {
    try {
      const response = await axios.post(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/register',
        { email, password, username },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const { idToken } = response.data;
      localStorage.setItem('token', idToken);
      await fetchUserData(idToken);
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, login, logout, register }}>
      {children}
    </UserContext.Provider>
  );
};
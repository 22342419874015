import React, { useState } from 'react'; // useStateをインポート
import { useNavigate } from 'react-router-dom'; // useNavigateをインポート
import '../styles/PointsPurchase.css'; // CSSファイルをインポート
import Modal from './Modal'; // モーダルコンポーネントをインポート

const PointsPurchase = ({ userRole }) => { // userRoleをpropsとして受け取る
  const navigate = useNavigate(); // useNavigateフックを使用
  const [showModal, setShowModal] = useState(false); // モーダルの表示状態を管理
  const [showTermsModal, setShowTermsModal] = useState(false); // 利用規約モーダルの状態
  const [selectedPrice, setSelectedPrice] = useState(null); // 選択された価格を管理
  const [agreedToTerms, setAgreedToTerms] = useState(false); // 利用規約への同意状態

  const pointOptions = [
    { price: 500, points: 470 },
    { price: 1000, points: 960 },
    { price: 3000, points: 3100 },
    { price: 5000, points: 5200 },
    { price: 10000, points: 10500 },
    { price: 15000, points: 16000 },
  ];

  const handlePurchase = (price) => {
    setSelectedPrice(price); // 選択された価格を設定
    setShowModal(true); // モーダルを表示
  };

  const handleConfirmPurchase = () => {
    if (agreedToTerms) {
      if (userRole === 'user') {
        navigate('/swipecomponent'); // swipecomponentに遷移
      } else {
        navigate('/register'); // registerに遷移
      }
    } else {
      setShowTermsModal(true); // 利用規約モーダルを表示
    }
    setShowModal(false); // モーダルを閉じる
  };

  const termsText = `
    利用規約

    本利用規約（以下「本規約」といいます）は、合同会社[会社名]（以下「当社」といいます）が提供するオンライン通話サービス「[サービス名]」（以下「本サービス」といいます）の利用条件を定めるものです。本サービスを利用される際には、本規約に同意したものとみなされます。

    第1条（適用）
    本規約は、本サービスの利用に関する当社とユーザーとの間の権利義務関係を定めるものであり、ユーザーは本規約に従って本サービスを利用するものとします。

  　第2条（定義）
本規約における用語の定義は以下の通りです。

「ユーザー」とは、本サービスを利用する18歳以上の個人を指します。
「アルバイト」とは、当社に所属し、本サービスを通じてユーザーと通話を行う従業員を指します。
「コンテンツ」とは、本サービスを通じて提供されるすべての音声、テキスト、画像、その他のデータを指します。

第3条（ユーザー登録）
ユーザーは、18歳以上であることを条件として、当社が定める手続きに従い、本サービスの利用登録を行うものとします。
登録情報は正確かつ最新のものであることが必要であり、変更が生じた場合、ユーザーは速やかに当社に通知するものとします。

第4条（サービスの内容）
本サービスは、ユーザーが当社のアルバイトとオンライン通話を行うことを目的としています。当社は通話相手を提供するものであり、ユーザーが通話中にどのような活動を行うか（例：オンラインゲームのプレイ）は、すべてユーザーの自由な判断によるものとします。
なお、ユーザーは、オンラインゲームの利用に関する規約や法律を自己責任で遵守するものとし、当社はこれに対する一切の責任を負いません。

第5条（禁止事項）
ユーザーは、以下の行為を行ってはならないものとします。

法令または公序良俗に反する行為
他のユーザーやアルバイトに対する嫌がらせや迷惑行為
当社または第三者の知的財産権を侵害する行為
本サービスを通じて取得した情報を無断で第三者に提供する行為
その他、当社が不適切と判断する行為

第6条（料金と支払い）
本サービスは有料で提供され、ユーザーは利用時間に応じた料金を当社が指定する方法で支払うものとします。
支払いは、クレジットカードや電子マネーを通じてStripeで行われ、支払済みの料金は原則として返金されません。ただし、当社が特別に認めた場合はこの限りではありません。

第7条（知的財産権）
本サービスに関するすべての知的財産権は、当社または正当な権利を有する第三者に帰属します。ユーザーは、当社の事前の許可なく、サービス内のコンテンツを複製、転用、販売、または頒布することはできません。

第8条（個人情報の取扱い）
当社は、ユーザーの個人情報を適切に管理し、本サービスの提供に必要な範囲でのみ利用します。
当社は、法令に基づく場合を除き、ユーザーの個人情報を第三者に提供することはありません。

第9条（免責事項）
当社は、ユーザーが本サービスを利用する際に生じた技術的な問題やサービス中のトラブルに対して一切の責任を負いません。また、ユーザーが通話中に行った他の行為（例：オンラインゲームのプレイ）に関連して発生した問題や法的責任についても、当社は一切関与せず、責任を負わないものとします。

第10条（サービスの利用停止・契約解除）
当社は、ユーザーが本規約に違反した場合、事前の通知なくアカウントを停止または契約を解除することができるものとします。
ユーザーは、いつでも本サービスの利用を停止し、アカウントの削除を申し出ることができます。

第11条（18歳未満の利用禁止）
本サービスは、18歳以上の方のみ利用可能です。18歳未満のユーザーが本サービスを利用した場合、当社は直ちにアカウントを停止し、必要に応じて法的措置を取ることができます。

第12条（準拠法および管轄裁判所）
本規約は日本法に準拠し、本サービスに関して生じた紛争については、[地域]地方裁判所を専属的合意管轄とします。

第13条（規約の変更）
当社は、必要に応じて本規約を変更することがあります。規約変更後にユーザーが本サービスを利用した場合、変更後の規約に同意したものとみなします。
  `;

  return (
    <div className="points-purchase-container">
      <h2>ポイント購入</h2>
      <div className="purchase-options">
        {pointOptions.map((option, index) => (
          <div key={index} className="purchase-card">
            <div className="points">{option.points}ポイント</div>
            <div className="price">¥{option.price}</div>
            <button onClick={() => handlePurchase(option.price)} className="purchase-button">
              購入
            </button>
          </div>
        ))}
      </div>
      <Modal show={showModal} handleClose={() => setShowModal(false)}>
        <h3>購入確認</h3>
        <p>{selectedPrice}円のポイントを購入しますか？</p>
        <div className="modal-button-container">
          <button onClick={handleConfirmPurchase} className="yes-button">はい</button>
          <button onClick={() => setShowModal(false)} className="no-button">いいえ</button>
        </div>
      </Modal>
      <Modal show={showTermsModal} handleClose={() => setShowTermsModal(false)}>
        <h3>利用規約に同意しますか？</h3>
        <div className="terms">{termsText}</div>
        <div className="modal-button-container">
          <button onClick={() => { setAgreedToTerms(true); setShowTermsModal(false); }} className="agree-button">同意する</button>
          <button onClick={() => setShowTermsModal(false)} className="disagree-button">同意しない</button>
        </div>
      </Modal>
    </div>
  );
};

export default PointsPurchase;

import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useNavigate } from 'react-router-dom'; // useNavigateをインポート
import '../styles/SwipeComponent.css'; // CSSをインポート

// 画像と情報をオブジェクトとして管理
const buddies = [
  {
    image: require('../assets/homeimage/image1.png'),
    name: 'ゆな',
    age: 21,
    hobby: 'Valorant'
  },
  {
    image: require('../assets/homeimage/image2.png'),
    name: 'なな',
    age: 19,
    hobby: 'FPS (OW、APEX)'
  },
  {
    image: require('../assets/homeimage/image3.png'),
    name: 'あかり',
    age: 22,
    hobby: '音楽'
  },
  {
    image: require('../assets/homeimage/image4.png'),
    name: 'さくら',
    age: 20,
    hobby: '映画'
  },
  {
    image: require('../assets/homeimage/image5.png'),
    name: 'りん',
    age: 23,
    hobby: '旅行'
  }
];

// 使用する色の配列
const colors = [
  { hex: '#FFC0CB', rgba: 'rgba(255, 192, 203, 1)' }, // ピンク
  { hex: '#FFECB3', rgba: 'rgba(255, 236, 179, 1)' }, // 
  { hex: '#E6E6FA', rgba: 'rgba(230, 230, 250, 1)' }, // ラベンダー
  { hex: '#D3D3D3', rgba: 'rgba(211, 211, 211, 1)' },
  { hex: '#FA8072', rgba: 'rgba(250, 128, 114, 1)' }  // サーモン
];

const SwipeComponent = ({ setBgColor, setSubMenuVisible }) => { // setBgColorをpropsとして受け取る
  const navigate = useNavigate(); // useNavigateフックを使用
  const [currentIndex, setCurrentIndex] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState('rgba(44, 62, 80, 1)'); // 初期背景色
  const [loading, setLoading] = useState(false); 
  
  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const handleSwipe = (direction) => {
    const randomColor = getRandomColor();
    setBgColor(randomColor.hex); // ランダムな色を設定
    setBackgroundColor(randomColor.rgba); // ランダムなRGBAを設定
    

    if (direction === 'left') {
      setCurrentIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % buddies.length;
        if (newIndex === 0) {
          // 5枚目をスワイプした後にローディングを開始
          setLoading(true);
          setTimeout(() => {
            navigate('/cast-list'); // cast-listに遷移
          }, 1000); // 1秒後に遷移// スワイプのアニメーション時間に合わせて調整
        }
        return newIndex;
      });
    } else if (direction === 'right') {
      setCurrentIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % buddies.length;
        if (newIndex === 0) {
          // 5枚目をスワイプした後にローディングを開始
          setLoading(true);
          setTimeout(() => {
            navigate('/cast-list'); // cast-listに遷移
          }, 1000); // 1秒後に遷移
        }
        return newIndex;
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const maxScroll = 500; // 最大スクロール量
      const brightness = Math.min(scrollY / maxScroll, 1); // 明るさの計算
      document.body.style.backgroundColor = `rgba(52, 73, 94, ${1 - brightness})`; // 背景色を設定
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // コンポーネントがマウントされたときにアニメーションをトリガー
  useEffect(() => {
    const buddyElement = document.querySelector('.buddy');
    if (buddyElement) {
      buddyElement.classList.add('slide-in'); // アニメーションを追加
      setTimeout(() => {
        buddyElement.classList.remove('slide-in'); // アニメーションを削除
      }, 500); // アニメーションの時間と一致させる
    }
  }, []);

  return (
    <div id="container" {...handlers} onMouseEnter={null} onMouseLeave={null}>
      {loading ? (
        <div className="loading-message">
          ロード中．．．
        </div>
      ) : (
        <>
          <div className="swipe-message top">スワイプしてもっと見る</div> {/* 上部メッセージ */}
          <div className="buddy">
            <div className="avatar" style={{ backgroundImage: `url(${buddies[currentIndex].image})` }}></div>
            <div className="info">
              <h3>{buddies[currentIndex].name}</h3>
              <p>年齢: {buddies[currentIndex].age}</p>
              <p>趣味: {buddies[currentIndex].hobby}</p>
            </div>
          </div>
          {/* メッセージを追加 */}
          <div className="swipe-instruction">
            最後までスワイプすると、次のページへ自動的に進みます。
          </div>
        </>
      )}
    </div>
  );
};

export default SwipeComponent;
